import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <div className="container" style={{ margin: "20px" }}>
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            What sets us apart is our unwavering commitment to excellence, our relentless pursuit of innovation, and our collective dedication to achieving greatness. Together, we have achieved remarkable milestones and overcome challenges that seemed insurmountable. Our success is not a coincidence; it's the result of each team member's exceptional skills, unyielding determination, and collaborative spirit. We don't settle for mediocrity; we strive for perfection in everything we do. Being the best isn't just a goal; it's our standard. With this team, there are no limits to what we can achieve dapibus leonec.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
